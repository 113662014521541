import firebase from 'firebase/app'
import configProd from './configurations/config.prod.json'
import configStaging from './configurations/config.staging.json'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
// import { notification } from 'ant-design-vue'

process.env.VUE_APP_FIREBASE_CONFIG === 'powerbase-1495a'
  ? firebase.initializeApp(configProd)
  : firebase.initializeApp(configStaging)

export const firebaseAuth = firebase.auth()
export const db = firebase.firestore()
export const firebaseFunctions = firebase.app().functions('asia-south1')
export const dbFieldPath = firebase.firestore.FieldPath
export const dbTimeStamp = firebase.firestore.Timestamp
export const dbFieldValue = firebase.firestore.FieldValue

const storage = firebase.storage()
export const storageRootRef = storage.ref()
export const storageImportRef = storage.ref('importTypes')
export const storageCustomersRef = storage.ref('customers')

// db.settings({ cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED })

if (location.hostname === 'localhost') {
  // firebaseAuth.useEmulator('http://localhost:9099')
  firebaseFunctions.useEmulator('localhost', 5001)
  db.settings({
    host: 'localhost:8080',
    ssl: false,
  })
}

// ISSUE: Persistence causes issue with startAfter queries.
// db.enablePersistence({ synchronizeTabs: true }).catch((err) => {
//   // TODO: Try adding logger.
//   if (err.code == 'failed-precondition') {
//     // Multiple tabs open, persistence can only be enabled
//     // in one tab at a a time.
//     console.log('failed-precondition: ', err)
//   } else if (err.code == 'unimplemented') {
//     // The current browser does not support all of the
//     // features required to enable persistence
//     notification.warning({
//       message: 'InCompatible Browser',
//       description: 'Please consider using another browser like Chrome for better experience.',
//     })
//   }
// })
